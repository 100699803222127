.invalid-code {
  border-color: red !important;
}
.container-1440 {
  width: 100%;
  padding-right: 70px;
  padding-left: 70px;
  max-width: 1440px;
  min-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.small-model-swiper {
  height: 100%;
}
.small-model-swiper .swiper-slide {
  cursor: pointer;
}
