html {
  height: 100%;
}
body {
  position: relative;
  margin: 0;
  display: flex;
  flex-flow: column;
  scroll-behavior: auto;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

code {
  font-family: "PT Sans", "Nunito Sans", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: "PT Sans", "Nunito Sans", sans-serif;
  color: #1f1f1f;
}

model-viewer::part(default-progress-bar) {
  background-color: #5dc7b7;
  border-radius: 50px;
}
