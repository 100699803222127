* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
}

body,
html {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.full {
  /*width: 100%;*/
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

header {
  margin: 0;
  padding: 0;
  width: 100%;
  background: linear-gradient(90deg, #000 0%, #1d1d1d 56.77%);
  display: flex;
  flex-direction: column;
}

.navigation-bar-mobile,
.mobile-header {
  display: none;
}

.desktop-header {
  width: 100%;
  background: linear-gradient(90deg, #000 0%, #1d1d1d 56.77%);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 103px;
}

p.name {
  z-index: 1;
  color: white;
  font-family: "PT Sans", sans-serif;
  font-size: 3.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.08px;
  margin-bottom: 23px;
}

.elipse1-mobile,
.elipse2-mobile,
.mobile-container-section2,
.pd5-mobile,
.gr36150 {
  display: none;
}

p.p1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 35px */
  letter-spacing: 0.25px;
}

.group {
  position: relative;
  align-self: flex-end;
}

.header-text {
  position: absolute;
  top: 45%;
  left: 15%;
  max-width: 60%;
}

img.gr36140 {
  display: block;
  max-width: 100%;
}

.gr36134 {
  padding-top: 91px;
  padding-right: 62px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  width: 50%;
}

nav.navigation-bar-desktop {
  padding-top: 29px;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 61px;
  position: fixed;
  width: 100%;
  top: 0;
  /*background: linear-gradient(90deg, #111 -3.44%, #1D1D1D 56.6%);*/
  z-index: 100;
}

.transparent-nav {
  background: none;
}

.full-nav {
  background: linear-gradient(90deg, #111 -3.44%, #1d1d1d 56.6%);
}

.navigation-bar-desktop-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 61px;
  row-gap: 10px;
}

.hide {
  opacity: 0;
}

.showl {
  opacity: 1;
}

nav.navigation-bar-desktop a {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  text-decoration: none;
  max-width: 100%;
}

nav.navigation-bar-mobile {
  display: none;
}

.gr1243 {
  width: 32px;
  height: 20px;
}

.login {
  border-radius: 30px;
  border-color: #64b39b;
  border-style: solid;
  background-color: #64b39b;
  padding: 6px 30px;
  text-align: center;
}

main {
  background: #fff;
  width: 100%;
}

.section1 {
  background-color: white;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 100px;
  padding-bottom: 110px;
}

.p2 {
  color: #1d1d1d;
  font-family: "PT Sans", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 65px */
  letter-spacing: 0.5px;
  margin-bottom: 77px;
}

.pd1 {
  color: #79c5c5;
  font-family: "PT Sans", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 58.5px */
  letter-spacing: 0.45px;
  border-radius: 10px;
  background: #e0fff1;
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 10px;
}

.pd2 {
  color: #1c1c20;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Zen Old Mincho", serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
  /* 39.2px */
  letter-spacing: -1px;
  margin-bottom: 25px;
}

.pd3 {
  color: #1c1c20;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "PT Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 23.8px */
  letter-spacing: 0.15px;
}

.division {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 115px;
  row-gap: 35px;
}

.div1,
.div2,
.div3,
.div4 {
  max-width: 300px;
  /*height: 16.25rem;*/
  flex-shrink: 0;
}

.division2 {
  background-color: #f4f4f4;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.group2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  margin-left: 83px;
  margin-right: 81.77px;
}

.gr36135 {
  margin-left: auto;
  margin-right: auto;
}

.gr36139 {
  align-self: flex-end;
}

.pd4 {
  color: #1d1d1d;
  font-family: "PT Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 56px */
  letter-spacing: 0.4px;
  margin-left: 83px;
  margin-right: 83px;
  margin-top: 66px;
  /* position: absolute;*/
}

.product {
  color: #5dc7b7;
  font-family: "PT Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.4px;
}

.pd5 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 35px */
  letter-spacing: 0.25px;
  margin-left: 83px;
  margin-right: 83px;
  margin-top: 66px;
  /*position: absolute;*/
}

.gr36135 {
  margin-top: 45px;
  margin-bottom: 135px;
  text-align: center;
  /* position: absolute;*/
  align-self: center;
  width: 367px;
  height: 681px;
}

.gr36136 {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
}

.gr36139 {
  max-width: 300px;
}

.section2 {
  max-width: 100%;
  min-height: 1467px;
  background: #1b1b1b;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 100px;
  padding-top: 100px;
}

.mobile-container-section3 {
  display: none;
}

.up {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
}

.down {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.ph {
  text-align: center;
  width: 100%;
}

.section22 {
  position: relative;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.d2 {
  position: absolute;
  left: 73px;
  top: 725px;
}

.d3 {
  position: absolute;
  left: 972px;
  top: 50px;
}

.gr36137 {
  position: absolute;
  left: 410px;
  top: 50px;
  max-width: 581px;
}

.d4 {
  position: absolute;
  left: 972px;
  top: 511px;
}

.d1 {
  position: absolute;
  top: 0;
  left: 0;
}

.d5 {
  position: absolute;
  left: 1029px;
  top: 828px;
}

.ps2 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 39px */
  text-align: center;
  border-radius: 20px;
  background-color: rgba(227, 227, 227, 0.33);
  display: inline-block;
  padding: 30px 70px;
  margin-bottom: 100px;
}

.d1,
.d2,
.d3,
.d4,
.d5 {
  /*
    border: 1px dashed #FFF;
    */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='3' stroke-dasharray='15%2c 22' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: fit-content;
}

.p1d1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 52px */
  margin-top: 50px;
  margin-left: 46px;
  margin-bottom: 34px;
}

.p2d1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-left: 46px;
  margin-right: 47px;
  margin-bottom: 99px;
}

.d2 {
  max-width: 17.5rem;
  margin-right: 48px;
}

.p1d2 {
  color: #96d8ce;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-left: 32px;
  margin-top: 23px;
}

.p2d2 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: 0.14px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 22px;
  margin-bottom: 22px;
}

.p1d3 {
  color: #96d8ce;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-top: 36px;
  margin-left: 39px;
  margin-bottom: 11px;
}

.p2d3 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: 0.14px;
  margin-left: 39px;
  margin-right: 39px;
  margin-bottom: 36px;
}

.d3 {
  max-width: 21.0625rem;
}

.p1d4 {
  color: #96d8ce;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-top: 22px;
  margin-left: 34px;
  margin-bottom: 11px;
}

.p2d4 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: 0.14px;
  margin-left: 34px;
  margin-right: 34px;
  margin-bottom: 21px;
}

.d4 {
  max-width: 21.0625rem;
}

.p1d5 {
  color: #96d8ce;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-top: 14px;
  margin-left: 44px;
  margin-right: 44px;
  margin-bottom: 11px;
}

.p2d5 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: 0.14px;
  margin-bottom: 14px;
  margin-left: 44px;
  margin-right: 44px;
}

.d5 {
  max-width: 17.5rem;
}

.d1 {
  max-width: 33rem;
}

.section3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.left {
  background-color: #e098d6;
  padding-left: 90px;
  padding-top: 100px;
}

.right {
  background-color: #5dc7c6;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  position: relative;
}

.gr36138 {
  align-self: center;
  position: absolute;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
}

.gr36138-1 {
  max-width: 90%;
  max-height: 100%;
}

.left1 {
  margin-bottom: 20px;
  color: #1c1c20;
  font-family: "PT Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 56px */
  letter-spacing: 0.4px;
}

.left2 {
  margin-bottom: 40px;
  color: #e4ffff;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 27.5px */
}

.left3 {
  margin-right: 61px;
  color: #000;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  margin-bottom: 90px;
}

footer {
  background-color: #000;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left {
  padding-top: 90px;
  padding-left: 90px;
}

.footer-right {
  padding-top: 111px;
  padding-right: 118px;
  padding-bottom: 115px;
  padding-left: 90px;
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 100px;
}

.left-footer1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 40px;
}

.left-footer2 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: -0.42px;
}

b.left-footer2 {
  font-weight: 700;
}

.social p,
.support p {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.6px;
}

.social a,
.support a {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.6px;
  text-decoration: none;
}

.social,
.support {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
}

.vector {
  margin-bottom: 20px;
}

.mobile-footer {
  display: none;
}

.dropdown {
  float: right;
  position: absolute;
  display: block;
  right: 15px;
  top: 37px;
}

.mobile-menu-links-container a {
  text-decoration: none;
  display: block;
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
  padding-bottom: 5px;
}

.menu-button {
  text-decoration: none;
  background: none;
  border: none;
}

.p1-links {
  color: #fff;
  text-align: left;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  padding-bottom: 25px;
}

.elipse3 {
  position: absolute;
  border-radius: 324px;
  background: rgba(150, 216, 206, 0.7);
  filter: blur(200px);
  transform: translate3d(0, 0, 0);
  width: 258px;
  height: 324px;
  left: 0px;
}

.p1-links:last-of-type {
  color: #5dc7b7;
  text-align: left;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

a.download {
  color: #5dc7b7;
  font-family: "PT Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
}

.mobile-menu-links-container {
  padding-left: 30px;
  padding-right: 15px;
  padding-top: 26px;
  text-align: left;
  position: absolute;
  background-color: #2a2a2a;
  width: 100%;
  height: 100vh;
  z-index: 1;
  right: 0;
  top: 0;
  display: none;
}

.menu-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.menu-content {
  height: 100%;
}

nav.navigation-bar-mobile {
  padding-bottom: 35px;
  padding-top: 26px;
  text-align: center;
  position: fixed;
  background-color: #1a1a1a;
  width: 100%;
  z-index: 100;
  top: 0;
}

.title1,
.logo-desktop {
  color: #5dc7b7;
  font-family: "PT Sans", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.7px;
}

b.merch1 {
  color: #fff;
  align-self: flex-end;
}

.mobile-header {
  border-radius: 20px;
  background: #d9e3e1;
  box-shadow: 10px 10px rgba(150, 216, 206, 0.46);
  position: relative;
  align-self: center;
  max-width: 328px;
}

.gr36149 {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
}

.gr36151 {
  padding-top: 17px;
  padding-right: 17px;
  max-width: 100%;
  max-height: 100%;
  float: right;
}

.p-top {
  color: #1a1a1a;
  font-family: "PT Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.3px;
  margin-top: 21px;
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 35px;
}

.showcase {
  color: #5dc7c6;
  font-family: "PT Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 42px */
  letter-spacing: 0.3px;
}

.d-model {
  color: #1a1a1a;
  font-family: "PT Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.3px;
}

.pd5-mobile-b {
  color: #f861e3;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 28px */
  letter-spacing: 0.2px;
}

.pd5-mobile {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.2px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 44px;
  z-index: 5;
}

.elipse1-mobile {
  position: absolute;
  top: -96px;
  left: 0px;
  width: 100%;
  height: 324px;
  flex-shrink: 0;
  z-index: 0;
  border-radius: 324px;
  background: #96d8ce;
  filter: blur(200px);
  transform: translate3d(0, 0, 0);
}

.elipse2-mobile {
  position: absolute;
  top: 500px;
  right: 0;
  width: 100%;
  height: 324px;
  flex-shrink: 0;
  border-radius: 324px;
  background: #96d8ce;
  filter: blur(200px);
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

.mobile-container-section2 {
  align-self: center;
  position: relative;
}

.s1 {
  color: #dd9ad6;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 36.4px */
  text-align: center;
  padding-bottom: 30.09px;
  z-index: 2;
}

.s1-2 {
  color: #fff;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  padding-bottom: 36px;
  z-index: 2;
  max-width: 335px;
}

.s1-3 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.18px;
  z-index: 2;
}

.screenshot-1 {
  border-radius: 20px;
  background: #f2f3f3;
  z-index: 2;
  padding-top: 30px;
  padding-left: 92px;
}

.screenshot-2 {
  border-radius: 20px;
  background: #f2f3f3;
  z-index: 2;
}

.screenshot-3 {
  border-radius: 20px;
  background: #d9d9d9;
  z-index: 2;
}

.s2 {
  color: #96d8ce;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
  letter-spacing: 0.18px;
  padding-bottom: 11px;
  z-index: 2;
  align-self: flex-start;
}

.s3 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 21px */
  letter-spacing: 0.15px;
  padding-bottom: 30px;
  align-self: flex-start;
  max-width: 335px;
}

.s3:last-of-type {
  padding-bottom: 45px;
}

.screenshot-1,
.screenshot-2,
.screenshot-3 {
  margin-bottom: 30px;
  width: 328px;
  max-height: 149px;
  flex-shrink: 0;
}

.section3-p1 {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 56px */
  letter-spacing: 0.4px;
  padding-top: 30px;
  padding-bottom: 46px;
  align-self: center;
}

.section3-p2 {
  color: #e4ffff;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 27.5px */
  padding-bottom: 46px;
  align-self: center;
}

.mobile-footer-2-social,
.mobile-footer-3-support {
  color: #fff;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.6px;
  padding-bottom: 10px;
}

.links-social a,
.links-support a {
  color: #8e8e8e;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 28px */
  letter-spacing: -0.6px;
  text-decoration: none;
}

.links-social,
.links-support {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  flex-wrap: wrap;
}

.mobile-footer-2 {
  padding-bottom: 25px;
}

.mobile-footer-3 {
  padding-bottom: 40px;
}

.mobile-footer-4 {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: -0.42px;
  padding-bottom: 32px;
}

@media screen and (max-width: 1530px) {
  .section22 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    align-items: center;
    align-self: center;
  }

  .section2 {
    min-height: auto;
  }

  .gr36137 {
    display: none;
  }

  .col1,
  .col3 {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    row-gap: 20px;
  }

  .d1,
  .d2,
  .d3,
  .d4,
  .d5 {
    margin: 0;
    position: static;
  }

  .section3 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 1140px) {
  p.name {
    font-size: 40px;
  }

  p.p1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .full {
    padding-top: 106px;
    background-color: #1a1a1a;
  }

  .show {
    display: block;
  }

  .disableScroll {
    overflow-y: hidden;
  }

  nav.navigation-bar-mobile {
    display: block;
  }

  nav.navigation-bar-desktop {
    display: none;
  }

  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  header {
    background: #1a1a1a;
    padding-left: 10%;
    padding-right: 10%;
  }

  main {
    background-color: #1a1a1a;
  }

  .section1 {
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 45px;
    padding-top: 43px;
  }

  .section2,
  .section3 {
    display: none;
  }

  .p2 {
    color: #fff;
    font-family: "PT Sans", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 52px */
    letter-spacing: 0.4px;
    margin-bottom: 44px;
    margin-left: auto;
    margin-right: auto;
  }

  .gr36139 {
    max-width: 100%;
  }

  .gr36150 {
    display: block;
    z-index: 0;
    max-width: 100%;
    min-width: 0;
  }

  .division {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 35px;
    margin-bottom: 35px;
  }

  .div1,
  .div2,
  .div3,
  .div4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .pd1 {
    color: #79c5c5;
    font-family: "PT Sans", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 58.5px */
    letter-spacing: 0.45px;
    border-radius: 10px;
    background: rgba(224, 255, 241, 0.13);
  }

  .pd2 {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Zen Old Mincho", serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    /* 39.2px */
    letter-spacing: -1px;
    text-align: center;
  }

  .pd3 {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "PT Sans", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 23.8px */
    letter-spacing: 0.15px;
  }

  .pd4 {
    font-family: "PT Sans", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 32.5px */
    letter-spacing: 0.25px;
    margin-top: 40px;
    margin-left: 41px;
    margin-right: 42px;
    margin-bottom: 38px;
    text-align: center;
    z-index: 5;
  }

  .product {
    font-family: "PT Sans", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.25px;
  }

  .pd5 {
    display: none;
  }

  .pd5-mobile {
    display: block;
  }

  .division2 {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    max-width: 328px;
    margin-bottom: 56px;
    z-index: 1;
  }

  .gr36139 {
    z-index: 3;
  }

  .group2 {
    z-index: 3;
  }

  .gr36136 {
    display: none;
  }

  .gr36150 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .gr36135 {
    display: none;
  }

  .elipse1-mobile {
    display: block;
  }

  .elipse2-mobile {
    display: block;
  }

  .mobile-container-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-container-section3 {
    background: linear-gradient(360deg, #5dc7c6 0%, #e098d6 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  .gr36138 {
    position: static;
    max-width: 100%;
  }

  .desktop-footer {
    display: none;
  }

  .mobile-footer {
    background-color: #000;
    flex-direction: column;
    display: flex;
  }

  .mobile-footer-1 {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    padding-top: 33px;
    padding-bottom: 35px;
  }

  .mobile-footer-2,
  .mobile-footer-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
